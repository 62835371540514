@charset "utf-8";
html {
  font-size: 62.5%
}

html>body {
  min-width: 0;
  overflow-x: hidden;
}

body {
  color: #333;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300;
  font-family: 'Noto Sans Japanese', meiryo, 'メイリオ', meiryo, 'ヒラギノ角ゴProW3', sans-serif;
  -webkit-text-size-adjust: 100%;
  letter-spacing: .1em;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.8;
  overflow-x: hidden;
  @include sp {
    font-size: 1.4rem;
  }
}

body, body * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body * {
  font: inherit;
  color: inherit;
  //white-space: inherit;
  outline: inherit;
  box-shadow: inherit;
  text-decoration: inherit;
}

*:focus {
  outline: 0
}

input::placeholder, textarea::placeholder {
  color: #ccc;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  outline: 0;
}

.cf:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden
}

.cf {
  min-height: 1px
}

* html .cf {
  height: 1px;
  /*\*/
  /*/height:auto;overflow:hidden;/**/
}

ul, ol {
  list-style: none
}

img {
  max-width: 100%;
  height: auto
}

a {
  text-decoration: none
}

a[href]:hover {
  opacity: .7
}

a:hover, .pagetop:hover, .l-header .-toggle:hover {
  cursor: pointer;
  opacity: .7;
}

body:not(.-pc) a:hover, body:not(.-pc) .pagetop:hover, body:not(.-pc) .l-header .-toggle:hover {
  opacity: 1;
}

input, textarea {
  display: block
}

main {
  display: block
}

.wrap {
  width: 89.33%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto
}

.flex {
  display: flex;
  &.justify-center {
    justify-content: center;
  }
}

@media (max-width: 767px) {

  .-max767 {
    display: block;
  }

  .-min768 {
    display: none;
  }
}

@media (min-width: 768px) {
  .-max767 {
    display: none;
  }

  .-min768 {
    display: block;
  }
}